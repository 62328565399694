import "../styles/home.css";

function HomePage() {
    return (
        <div>
            <h1>JUDE OFFORD</h1>
            <p>- under construction -</p>
        </div>
    )
}

function Home() {
    return (
        <>
            <HomePage />
        </>
    );
}

export default Home;