function NotFoundPage() {
    return (
        <div>
            <h1>NOT FOUND</h1>
        </div>
    )
}

function NotFound() {
    return (
        <>
            <NotFoundPage />
        </>
    );
}

export default NotFound;